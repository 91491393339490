import React from "react"
import * as containerStyles from "./ReleaseNotes.module.css"
import bolt from "../../images/ergsense-bolt.svg"

const ReleaseNotes = props => {
  return (
    <div className="bg-gray-50 py-8">
      <div className="flex justify-center items-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 mr-2">
          {props.notesHeadingText}
        </h2>
        <img src={bolt} alt="" width={50} height={50} />
      </div>
      {props.notes.cards.map(card => {
        return (
          <div
            className={`bg-white max-w-5xl shadow-lg p-8 my-8 mx-auto rounded-lg text-gray-600 ${containerStyles.releaseContent}`}
            dangerouslySetInnerHTML={{ __html: card.text }}
          ></div>
        )
      })}
    </div>
  )
}

export default ReleaseNotes
