import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import * as featuresStyles from "./ProductFeatures.module.css"
const features = [
  {
    id: 1,
    name: "Order D.HAT PI4 KIT",
    description:
      "The leather cover and machined steel disc binding stand up to daily use for years to come.",
    listItems: [
      `Review the <a href="/products/d-hat-product-development-kit">order solution</a>`,
    ],
    color: "bg-green-100 text-green-800",
  },
  {
    id: 2,
    name: "PROTOTYPE & TEST",
    description:
      "Buy it once and refill as often as you need. Subscribe and save on routine refills.",
    listItems: [
      "Full 3 phase power meter & application processing",
      "Raspberry Pi HAT standard form factor",
      "Serial, WiFi, BT and SPI interfaces",
    ],
    color: "bg-yellow-100 text-yellow-800",
  },
  {
    id: 3,
    name: "DEVELOP & FLY",
    description:
      "The comfortable disc binding allows you to quickly rearrange pages or combine lined, graph, and blank refills.",
    listItems: [
      "Open Source D.HAT HW design",
      "DTECTS™ DSZ binary & Zerynth Python libraries",
      "APIs for AWS and Grafana FOTA, database and presentation services",
    ],
    color: "bg-blue-100 text-blue-800",
  },
  {
    id: 4,
    name: "SCALE +",
    description:
      "Responsibly and sustainably made real close to wherever you are, somehow.",
    listItems: [
      "Think big, start small and prepare to scale+",
      "Scale+ to licence and embed patented DTECTS™ IP inside your very own OEM products",
    ],
    color: "bg-pink-100 text-pink-800",
  },
]

const ProductFeatures = () => {
  return (
    <div className="bg-gray-50">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-h-2 overflow-hidden lg:aspect-none lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-16">
          <StaticImage
            src="../../images/smart-machines.jpg"
            alt="Black leather journal with silver steel disc binding resting on wooden shelf with machined steel pen."
            className="h-full w-full object-center object-fit lg:h-full lg:w-full"
            objectPosition="80%"
          />
        </div>

        <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:pb-32 sm:px-6 lg:max-w-7xl lg:pt-32 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-16">
          <div className="lg:col-start-2">
            <h2
              id="features-heading"
              className="font-medium text-primaryDarker"
            >
              DTECTS Toolbox
            </h2>
            <p className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight">
              Embedded AI on the Fly
            </p>
            <p className="mt-4 text-gray-600">
              We understand that that the time and efforts to grow from Seed to
              Flower don’t come overnight so we created the DTECTS toolbox to
              enable the world to make machines intelligent. With the simplicity
              of adding HATs on top of PI Compatible SBCs we created Developer
              Kits for any industry, your journey will start on the fly. Plug
              and Play, with open-source hardware and BSP, your first prototypes
              will be fit to scale.
            </p>

            <dl className="mt-10 grid grid-cols-1 gap-y-10 gap-x-8 text-sm sm:grid-cols-2">
              {features.map(feature => (
                <div key={feature.name}>
                  <dt className="font-medium text-secondary">
                    <span
                      className={`inline-flex items-center px-2.5 py-1 mr-2 rounded-full text-md font-medium ${feature.color}`}
                    >
                      {feature.id}
                    </span>
                    {feature.name}
                  </dt>

                  <dd className="mt-2 text-gray-600">
                    <ul style={{ listStyle: "inside" }}>
                      {feature.listItems.map(listItem => (
                        <li
                          dangerouslySetInnerHTML={{ __html: listItem }}
                          className={featuresStyles.productLinks}
                        ></li>
                      ))}
                    </ul>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProductFeatures
