import React from "react";
import dtects from "../../images/dtects.gif";
import { StaticImage } from "gatsby-plugin-image";
import * as containerStyles from "./HowItWorks.module.css";
const HowItWorks = () => {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto px-4 py-24 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
        {/* Details section */}
        <section aria-labelledby="details-heading">
          <div className="flex flex-col items-center text-center">
            <h2
              id="details-heading"
              className="text-3xl font-base tracking-tight text-secondary sm:text-4xl"
            >
              <b>
                <i>Intelligence</i>
              </b>{" "}
              Where The{" "}
              <b>
                <i>Action</i>
              </b>{" "}
              Is
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-600">
              DTECTS™ <b>– For the first time –</b> "Integration of industrial
              power metrology with state of the art edge machine learning and
              analytics"
            </p>
            <img src={dtects} className="mb-4" />
          </div>
          <div className="flex flex-col items-center text-center">
            <h2
              id="details-heading"
              className="text-3xl font-extrabold tracking-tight text-secondary sm:text-4xl"
            >
              How It Works
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-600">
              Integration of industrial power metrology with state-of-the-art
              EDGE machine learning and analytics. A development toolbox that
              accelerates your machine intelligence to market on a development
              ready platform.
            </p>
          </div>

          <div className="mt-16 text-center flex flex-wrap justify-center">
            <div className={`mx-4 ${containerStyles.flexItem}`}>
              <div className="rounded-lg">
                <StaticImage
                  src="../../images/1.webp"
                  alt="Drawstring top with elastic loop closure and textured interior padding."
                />
              </div>
              <h3
                id="details-heading"
                className="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-2xl mt-4"
              >
                REDUCE Data Gravity
              </h3>
              <p className="text-base my-1 text-primaryDarker">
                Deliver distributed applications at the EDGE
              </p>
              <p className="text-base text-gray-500">
                Artificial Intelligence is applied to discriminate meaningful
                events and gives output to both the user and the Machine
                Learning engine. - Enable your EDGE applications with real time
                AI
              </p>
            </div>
            <div className={`mx-4 ${containerStyles.flexItem}`}>
              <div className="rounded-lg">
                <StaticImage
                  src="../../images/2.webp"
                  alt="Drawstring top with elastic loop closure and textured interior padding."
                />
              </div>
              <h3
                id="details-heading"
                className="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-2xl mt-4"
              >
                INFUSE Domain Knowledge
              </h3>
              <p className="text-base my-1 text-primaryDarker">
                Make your products intelligent
              </p>
              <p className="text-base text-gray-500">
                DTECTS™ is a feedback loop in which OEM specific Domain
                Knowledge generates the event triggers with Key Performance
                Indicators while Machine learning refines the vectors. – Get
                started with the D.HAT toolbox to deliver your MVP for
                electrically connected assets
              </p>
            </div>
            <div className={`mx-4 ${containerStyles.flexItem}`}>
              <div className="rounded-lg">
                <StaticImage
                  src="../../images/3.webp"
                  alt="Drawstring top with elastic loop closure and textured interior padding."
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <h3
                id="details-heading"
                className="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-2xl mt-4"
              >
                Embed AI/ML inside YOUR products
              </h3>
              <p className="text-base my-1 text-primaryDarker">
                Let's solve your use case togther
              </p>
              <p className="text-base text-gray-500">
                In additional to D.HAT the DTECTS™ embedded package (128Kb code
                only) is a truly horizontal ML/AI in the core were any signal
                can be described by the DTECTS™ Key Performance Indicators.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HowItWorks;
