import * as React from "react"
import { graphql } from "gatsby"
import Metrics from "../components/Metrics/Metrics"
import ProductFeatures from "../components/ProductFeatures/ProductFeatures"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import { StaticImage } from "gatsby-plugin-image"
import ReleaseNotes from "../components/ReleaseNotes/ReleaseNotes"
import Seo from "../components/Seo/Seo"

const OurServicesPage = ({ data: { wpPage } }) => {
  return (
    <>
      <Seo title="Our Services" />
      <div style={{ display: "grid" }}>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
            maxHeight: 350,
            opacity: 0.25,
          }}
          // You can optionally force an aspect ratio for the generated image
          aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          placeholder="none"
          src={"../images/iot-background.jpg"}
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="text-center my-10 sm:my-20">
            <h2 className="text-3xl text-secondary font-extrabold tracking-tight sm:text-4xl">
              {wpPage.titleBanner.headingText}
            </h2>
            <p className="mt-2 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {wpPage.titleBanner.subheadingText}
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              {wpPage.titleBanner.paragraphText}
            </p>
          </div>
        </div>
      </div>
      <ProductFeatures />
      <HowItWorks />
      <ReleaseNotes
        notesHeadingText={wpPage.releaseNotes.releaseNotesHeading}
        notes={wpPage.releaseNotes}
      />
      <Metrics />
    </>
  )
}

export default OurServicesPage

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 201 }) {
      titleBanner {
        headingText
        subheadingText
        paragraphText
      }
      releaseNotes {
        releaseNotesHeading
        cards {
          text
        }
      }
    }
  }
`
