import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const metrics = [
  {
    id: 1,
    stat: "25 Million +",
    text: "Raspberry PIs ready for D.HAT",
  },
  {
    id: 2,
    stat: "250,000",
    text: "Students, Makers and Professionals engaged every week",
  },
  {
    id: 3,
    stat: "6 decades",
    text: "Industrial subject matter expertise",
  },
]

const Metrics = () => {
  return (
    <div className="relative bg-gray-900">
      <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <StaticImage
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="../../images/engineer-monitoring.png"
              alt="People working on laptops"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold text-primaryLighter tracking-wide uppercase">
            Why ErgSense D.HAT
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">
            We love to keep things easy for everyone
          </p>
          <p className="mt-5 text-lg text-gray-300">
            According to VentureBeat.AI, only 1 out of 10 .ai projects hit the
            market and Gardner claims 80% of analytics insights will not deliver
            business outcomes through 2022 and 80% of AI projects will “remain
            alchemy, run by wizards” through 2020. We help ensure your AI
            projects succeed.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {metrics.map(item => (
              <p key={item.id}>
                <span className="block text-2xl font-bold text-white">
                  {item.stat}
                </span>
                <span className="font-medium text-white">{item.text}</span>{" "}
              </p>
            ))}
            <div>
              <Link
                to="/products/d-hat-product-development-kit"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-secondary hover:bg-secondaryDarker"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Metrics
